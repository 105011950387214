<template>
    <div class="sgirot-create"
         :class="{'rtl' : isLanguageRtl()}">

        <div class="toolbar uk-flex uk-flex-between">
            <div class="actions" style="margin-inline-start: auto;">
                <div class="uk-button-group uk-flex-gap-s">
                    <form-button
                            type="secondary"
                    @click="doCancel">{{translate('sgirot.general.cancelButton')}}</form-button>
                    <form-button
                            @click="doSubmit">{{translate('sgirot.general.createButton')}}</form-button>
                    <slot name="actionButtons"></slot>
                </div>
            </div>
        </div>
        <slot name="beforeForm"></slot>


        <base-form
            :key="key"
                v-if="finalFormConfig"
                ref="createForm"
                :action="subject"
                method="post"
                v-model="item"
                :config="finalFormConfig"
                v-bind="formProps"
                @form:submitSuccess="afterSubmit"
        ></base-form>

        <base-form
                v-else
                ref="createForm"
                :action="subject"
                method="post"
                v-model="item"
                v-bind="formProps"
                :formPath="formSource"
                :config="{context: 'create'}"
                @form:submitSuccess="afterSubmit"
        ></base-form>
        <slot name="afterForm"></slot>
    </div>
</template>

<script>
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
import {useStore} from "vuex";
import {computed} from "vue";


export default {
    setup (props, setupContext ) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());

        return {asyncOps, asyncOpsReady, asyncStatus,}
    },
    props: {
        subject: {
            type: String,
            default: 'source',
        },
        formSource: {
            type: String
        },
        formConfig : {
            type: [Boolean, Object],
            default: false
        },
        itemSource: {
            type: String,
            default : '',
        },
        redirect: {
            type: [String, Object, Function],
            default: '',
        },
        cancelHandler : {
            type: [Boolean, Function],
            default: false,
        },
        formProps : {
            type: [Object],
            default: {}
        },
        initialItemData : {
            type: Object,
            default: {}
        }
    },
    data: function () {
        return {
            item : {...this.initialItemData},
            oldItem : {...this.initialItemData},
            key : utilities.getUniqueNumber()
        }
    },
    computed: {
        finalFormConfig () {
            return this.formConfig || false;
        }

    },
    emits: ['form:changed'],
    methods: {
        setFormValue (key, value) {
            this.item[key] = value;
        },
        doRedirect() {
            if (typeof this.redirect === 'function') {
              return this.redirect();
            }
            this.$router.push(this.redirect);
        },
        doCancel () {
            if (this.cancelHandler) {
                return this.cancelHandler();
            }
            this.doRedirect();
        },
        doSubmit () {
            this.$refs.createForm.submit();
        },
        afterSubmit () {
            this.$s.ui.notification(this.translate('sgirot.general.actionSuccessful'), 'success');
            this.doRedirect();
        },
        clear() {
            this.item = {...this.initialItemData}
            this.key = utilities.getUniqueNumber();
        }
    },
    watch : {
        item :{
            handler: function (newVal) {
                // detect the changed value
                let changedKey = null,
                    changeValueFrom = null,
                    changeValueTo = null,
                    isKeyNew = false;

                for ( const [key, oldEntry] of Object.entries(this.oldItem)) {
                    if (oldEntry == newVal[key]) {
                        continue;
                    }

                    this.$emit('form:changed', {
                        newVal,
                        oldVal : this.oldItem,
                        changedKey : key,
                        changeValueFrom : oldEntry,
                        changeValueTo : newVal[key],
                        isKeyNew : false
                    });
                    this.oldItem = {...newVal};
                    return;
                }

                for ( const [key, newEntry] of Object.entries(newVal)) {
                    if ( !  this.oldItem.hasOwnProperty(key)) {
                        changedKey = key;
                        changeValueFrom = undefined;
                        changeValueTo = newVal[key];
                        isKeyNew = true;
                        break;
                    }
                }

                // if we cant find the changed value, it means it is new. look for a new value
                this.$emit('form:changed', {newVal, oldVal :  this.oldItem, changedKey, changeValueFrom, changeValueTo, isKeyNew});
                this.oldItem = {...newVal};
                return;
            },
            deep: true,
        }
    }


}
</script>

<style scoped lang="scss">


</style>
